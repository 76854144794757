import React from "react";
import { graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
//import HomeHero from "./HomeHero";
//data.site.siteMetadata.title
//    <HomeHero />
const shortcodes = { Container, Row, Col, OutboundLink };
const PostLayout = ({ data: { mdx } }) => {
  const header = <Header siteTitle={"a"} />;
  return (
    <>
      {header}
      <MDXProvider components={shortcodes}>
        <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <Footer />
    </>
  );
};

export default PostLayout;

export const query = graphql`
  query postQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        author
        updated
        slug
      }
      body
    }
  }
`;
